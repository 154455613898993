<template>
    <div class="content">
        <el-row class="bgW">
            <el-col :span="22">

                <!--检索条件-->
                <el-form 
                    class="search" 
                    :inline="true" 
                    size="mini" 
                    :model="search"
                >
                    <el-form-item label="疤痕大类">
                        <el-select 
                            v-model="search.type1" 
                            clearable 
                            class="w150" 
                            @change="remoteMethod"
                            placeholder="请选择疤痕大类"
                        >
                            <el-option
                                v-for="item in options.type1"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="疤痕小类">
                        <el-select 
                            v-model="search.type2" 
                            clearable class="w150" 
                            placeholder="请选择疤痕小类"
                            @change="changeSecond"
                        >
                            <el-option
                                v-for="item in options.type2"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="治疗方式" v-if="hasSons == 1">
                        <el-select 
                            v-model="search.type3" 
                            clearable class="w80" 
                            placeholder="请选择治疗方式"
                            @change="changeSecond2"
                        >
                            <el-option
                                v-for="item in options.type3"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item label="长度" v-if="is_fill_out == 1">
                        <el-input
                            v-model="search.length"
                            maxlength="30"
                            class="w80"
                            placeholder=""
                            clearable
                        >
                        </el-input>  cm
                    </el-form-item>

                    <el-form-item label="面积" v-if="is_fill_out == 2">
                        <el-input
                            v-model="search.area"
                            maxlength="30"
                            class="w80"
                            placeholder=""
                            clearable
                        >
                        </el-input>  cm²
                    </el-form-item>

                    <el-form-item label="疗程次数" prop="cureNum">
                        <el-input
                            v-model="search.cureNum"
                            class="w50"
                            placeholder=""
                        /> 次
                    </el-form-item>
                    
                    <el-form-item>
                        <el-checkbox v-model="search.checked">医疗价格（8.5折优惠）</el-checkbox>
                    </el-form-item>
                    
                </el-form>
                
            </el-col>

            <el-col :span="2" style="text-align: right;">
                <el-button type="primary" icon="el-icon-search" size="mini" @click="submit()">确定</el-button>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">定价规则：</el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <div>{{content}}</div>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">总金额：{{ amount }}</el-col>
        </el-row>
    </div>
  
</template>

<script>
import { mapGetters } from "vuex";
import { getScarReckoner,getSecondType,scarReckonerSubmit } from "@/api/shop";

class Search {
    type1   = ''; // 疤痕大类
    type2   = ''; // 疤痕小类
    type3   = ''; // 归属市场
    length  = ''; // 长度
    area    = ''; // 面积
    cureNum = ''; // 疗程次数
    checked = false;
}

class Options {
    constructor() {
        this.type1 = [];
        this.type2 = [];
        this.type3 = [];
    }
}

export default {
    data() {
        return {
            search: new Search(), // 检索条件
            options: new Options(), // 选项
            list: [],
            list2: [],
            content:'',
            hasSons:'',
            amount:'',
            is_fill_out:'',
        }
    },
    mounted() {
        this.getIndex();
    },
    methods: {
        getIndex(){
            getScarReckoner({}).then(res => {
                this.options.type1 = res.data;
            });
        },

        remoteMethod(e){
            this.options.type2 = [];
            this.options.type3 = [];

            getSecondType({ firstId:e }).then(res =>{
                // console.log(res);
                this.options.type2 = res.data;
            })

            this.search.type2 = '';
            this.search.type3 = '';
            this.content = '';
            this.amount = '';
            this.hasSons = '';
            this.is_fill_out = '';

            
        },

        changeSecond(e){
            this.options.type3 = [];

            this.search.length = '';
            this.search.area = '';
            // console.log(e);

            let arr = this.options.type2;

            arr.forEach(item => {
                // console.log(item.id);
                if(e == item.id){
                    this.content = item.content;
                    
                    this.hasSons = item.has_sons;
                    // this.is_fill_out = item.is_fill_out;
                }
            });

            getSecondType({ firstId:e }).then(res =>{
                // console.log(res);
                this.options.type3 = res.data;
            })

            this.search.type3 = '';
            this.amount = '';
            this.is_fill_out = '';
            
        },

        changeSecond2(e){
            console.log(e);

            let arr3 = this.options.type3;

            arr3.forEach(item => {
                // console.log(item.id);
                if(e == item.id){
                    
                    this.is_fill_out = item.is_fill_out;

                }
            });

        },

        submit(){
            scarReckonerSubmit({ ...this.search, }).then(res =>{
                console.log(res);
                // this.options.type3 = res.data;
                this.amount = res;
            })
        },
    }
};
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px 20px 3px 0;
}
.accountCon {
  background: #fff;
  padding: 10px 0px 80px;
}
</style>